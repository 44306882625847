<template>
    <div> 
        <div class="card my-4 col-6 offset-3 p-0" v-if="this.service_center">
            <div class="card-body alert-secondary text-center">
                <date-form-entry :label="'Check In Date (' + this.service_center.timezone + ')'" :modelValue="this.return.recieved_date ? this.return.recieved_date_localized_formatted : this.return.recieved_date_localized" v-on:valueUpdate="recievedDateUpdate" :includeSetToNow="true" :is-disabled="!this.editable" :is-required="true" />
                <text-form-entry label="SupportSync Return" v-model="this.return.supportsync_reference" :is-disabled="!this.editable"/>
                <text-form-entry :class="{'mb-0': this.return.zendesk_reference}" label="Zendesk Number" v-model="this.return.zendesk_reference" :is-disabled="!this.editable"/>
                <div v-if="checkingZendeskTicket" class="small text-secondary p-0 m-0">
                    <p>Checking for ZD Ticket...</p><span class="spinner-border spinner-border-sm" role="zd_ticket_check_loading"></span>
                </div>
                <p v-if="this.return.zendesk_reference && !this.matchedZendeskTicket && checkingZendeskTicket !== true" class="small text-danger">Zendesk ticket not found. Are you sure?</p>
                <a v-if="this.return.zendesk_reference && this.matchedZendeskTicket && checkingZendeskTicket !== true" :href="'https://swytchbike.zendesk.com/agent/tickets/' + this.zendeskReferenceNumbers" class="mt-n3" target="_blank"><small>View Ticket in Zendesk</small></a>
                <text-form-entry label="Other Reference" v-model="this.return.other_reference" :is-disabled="!this.editable"/>

                <select-form-entry :is-disabled="!this.editable" label="Return Reason" :options="this.returnReasonOptions" v-model="this.return.return_reason" :is-required="true"/>

                <div v-if="this.editable" class="form-group row">
                    <label class="col-sm-4 col-form-label">Return SKU</label>
                    <div class="col-sm-8">
                        <div class="bg-white">
                            <v-select :disabled="!this.editable" :options="this.skuOptions" label="name" v-model="this.return.sku_id" :reduce="sku => sku.value"></v-select>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <text-form-entry label="Return SKU" v-model="this.return.sku_name" :is-disabled="true"/>
                </div>

                <select-form-entry :is-disabled="!this.editable" label="Technician" :options="this.userOptions" v-model="this.return.check_in_user_id" :is-required="true"/>

                <div v-if="Object.keys(this.formErrors).length" class="col-12 text-center mt-3 text-danger">
                    <h3>Errors:</h3>
                    <ul v-for="(errField, val) in this.formErrors" :key="errField">
                        <li v-for="errMsg in errField" :key="errMsg">
                            <strong>{{ val }}:</strong> {{ errMsg }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else>
            <span class="spinner-border spinner-border-sm" role="data_loading"></span>
        </div>

    </div>
</template>

<script>
import DateFormEntry from '../../../../Components/Form/DateFormEntry.vue';
import TextFormEntry from '../../../../Components/Form/TextFormEntry.vue';
import SelectFormEntry from '../../../../Components/Form/SelectFormEntry.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';
import moment from 'moment';

export default {
    components: { DateFormEntry, TextFormEntry, SelectFormEntry, vSelect },
    props: {
        modelValue: Object,
        editable: {
            type: Boolean,
            default: false
        },
        serviceCenterId: Number,
        formErrors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            service_center: null,
            users: [],
            return: {},
            checkingZendeskTicket: false,
            matchedZendeskTicket: null,
            returnReasons: [],
            skus: [],
        };
    },
    computed: {
        userOptions() {
            return this.users.map(user => ({
                name: user.text,
                value: user.value
            }));
        },
        returnReasonOptions() {
            let opt = [];
            for (const [key, value] of Object.entries(this.returnReasons)) {
                opt.push({
                    name: value,
                    value: key
                });
            }
            return opt;
        },
        skuOptions() {
            return [...this.skus].sort((a, b) => b.return_items_count-a.return_items_count).map(sku => ({
                name: sku.sku,
                value: sku.id
            }));
        },
        zendeskReferenceNumbers() {
            return this.return?.zendesk_reference?.trim()?.replace('ZD-', '');
        }
    },
    created () {
        axios.get('/api/tools/servicecenter/users')
            .then(({data}) => {
                this.users = data;
            });
        axios.get('/api/tools/servicecenter/' + this.serviceCenterId)
            .then(({data}) => {
                this.service_center = data;
                if (this.return.recieved_date_localized === null) {
                    this.return.recieved_date_localized = moment.tz(new Date(), moment.tz.guess()).tz(this.service_center.timezone).format('yyyy-MM-DD HH:mm:ss');
                }
            });
        axios.get('/api/tools/servicecenter/reasons')
            .then(({data}) => {
                this.returnReasons = data;
            });
        axios.get('/api/sku/')
            .then(({data}) => {
                this.skus = data;
            });
        this.return = this.modelValue ?? { check_in_user_id: null };
        if (this.return?.check_in_user_id === null) {
            this.return.check_in_user_id = window.Laravel['user_id'];
        }
    },
    watch: {
        'return.zendesk_reference'(newZdRef, oldZdRef) {
            if (newZdRef && newZdRef != oldZdRef && this.zendeskReferenceNumbers) {
                this.checkZendeskTicket();
            }
        },
        return: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue);
            },
            deep: true
        }
    },
    methods: {
        recievedDateUpdate(newValue) {
            this.return.recieved_date_localized = newValue;
        },
        checkZendeskTicket() {
            this.checkingZendeskTicket = true;
            this.matchedZendeskTicket = null;
            axios.get('/api/zendesk/tickets/' + this.zendeskReferenceNumbers + '/exists')
                .then(({data}) => {
                    this.matchedZendeskTicket = data;
                    this.checkingZendeskTicket = false;
                }).catch(() => {
                    this.matchedZendeskTicket = false;
                    this.checkingZendeskTicket = false;
                });
        },
    },
};
</script>