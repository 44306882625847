<template>
    <div class="fixed bottom-2 right-2">

        <v-modal header="Leave feedback" ref="modal">
            <template #activator="{show}">
                <div class="group" style="position: relative; z-index: 10;">
                    <button
                        class="p-0 w-10 h-10 bg-blue rounded-full hover:bg-blue-500 active:shadow-lg mouse shadow transition ease-in duration-100 focus:outline-none"
                        @click="show">
                        <i class="fa fa-comment text-blue-air"></i>
                    </button>

                    <div class="z-100" style="z-index: 100;">
                        <span
                            class="-top-7 right-0 whitespace-pre pointer-events-none absolute w-max rounded bg-gray-900 px-2 py-1 text-sm font-medium text-gray-50 opacity-0 shadow transition-opacity group-hover:opacity-100">
                            Leave a comment or report a bug.
                        </span>
                    </div>

                    <!--                    <a href="/tools/user-feedback/user-feedback">Feedback</a>-->
                </div>

            </template>
            <div>

                <div class="px-20">
                    <a href="/tools/user-feedback/user-feedback" v-if="hasFeedback">View Feedback</a>
                    <br/>
                    <div class="pt-10 flex flex-col">
                        <div>
                            <label for="types">Type</label>
                            <select id="types" v-model="type">
                                <option v-for="type in types" :key="type" :value="type">
                                    {{ type }}
                                </option>
                            </select>
                        </div>

                        <div>
                            <label for="title">Title</label>
                            <input type="text" id="title" v-model="title"/>
                        </div>

                        <div>
                            <label for="text">Text</label>
                            <textarea rows="4" id="text" v-model="text"/>
                        </div>

                        <div>
                            <label for="screenshot">Take a screenshot?</label>
                            <input type="checkbox" id="screenshot" v-model="takeScreenshot"/>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <v-button :disabled="isValid === false"  @click="$emit('submit')" :loading="submittingFeedback">
                    Leave feedback
                </v-button>
            </template>
        </v-modal>


    </div>
</template>

<script setup lang="ts">
import VModal from '../../../../tools/watson/resources/js/src/components/Atoms/VModal.vue';
import VButton from '../../../../tools/watson/resources/js/src/components/Atoms/VButton.vue';
import {UserFeedbackSchema} from '../../Types/UserFeedback';
import {computed, ref, WritableComputedRef} from 'vue';

const props = withDefaults(defineProps<{
    isValid: boolean;
    schema: UserFeedbackSchema,
    types: string[],
    submittingFeedback: boolean,
    hasFeedback: boolean
}>(), {
    isValid: false
});

const emit = defineEmits<{
    (e: 'update:schema', schema: UserFeedbackSchema): void;
    (e: 'submit'): void;
}>();

const modal = ref<VModal|null>(null);

function close() {
    modal.value?.close();
}

const calculatedSchema: WritableComputedRef<UserFeedbackSchema> = computed({
    get(): UserFeedbackSchema {
        return props.schema;
    },
    set(newValue: UserFeedbackSchema): void {
        emit('update:schema', newValue);
    },
});

const type = computed<string>({
    get(): string {
        return calculatedSchema.value.type;
    },
    set(newValue: string): void {
        calculatedSchema.value.type = newValue;
    },
});

const title = computed<string|null>({
    get(): string|null {
        return calculatedSchema.value.title;
    },
    set(newValue: string|null): void {
        calculatedSchema.value.title = newValue;
    },
});

const text = computed<string|null>({
    get(): string|null {
        return calculatedSchema.value.text;
    },
    set(newValue: string|null): void {
        calculatedSchema.value.text = newValue;
    },
});

const takeScreenshot = computed<boolean>({
    get(): boolean {
        return calculatedSchema.value.takeScreenshot;
    },
    set(newValue: boolean): void {
        calculatedSchema.value.takeScreenshot = newValue;
    },
});

defineExpose({
    close
});

</script>

<style scoped>

</style>
