<template>
    <button type="button" class="btn btn-primary user-feedback-floating" style="background-color: rgb(13 21 41); border-radius: 9999px;" data-toggle="modal" data-target="#exampleModal">
        <i class="fa fa-comment" style="color: rgb(163 180 218)"></i>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Leave feedback</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <a href="/tools/user-feedback/user-feedback" v-if="hasFeedback">View Feedback</a>

                    <div class="form-group">
                        <label for="type-field">Type</label>
                        <select v-model="type" class="form-control" id="type-field">
                            <option v-for="type in types" :key="type" :value="type">
                                {{ type }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="title-field">Title</label>
                        <input type="text" v-model="title" class="form-control" id="title-field" aria-describedby="titleFieldHelp" placeholder="Enter a title...">
                        <small id="titleFieldHelp" class="form-text text-muted">This should be a short, few word summary of the bug/your idea.</small>
                    </div>
                    <div class="form-group">
                        <label for="text-field">Body</label>
                        <textarea v-model="text" rows="4" class="form-control" id="text-field">

                        </textarea>
                    </div>
                    <div class="form-check">
                        <input v-model="takeScreenshot" type="checkbox" class="form-check-input" id="takeScreenshot-field">
                        <label class="form-check-label" for="takeScreenshot-field">Allow a screenshot to be taken?</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" :disabled="isValid === false || submittingFeedback" @click="$emit('submit')">
                        <span v-if="submittingFeedback">
                            Leaving Feedback...
                        </span>
                        <span v-else>
                            Leave Feedback
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import {UserFeedbackSchema} from '../../Types/UserFeedback';
import {computed, WritableComputedRef} from 'vue';

const props = withDefaults(defineProps<{
    isValid: boolean;
    schema: UserFeedbackSchema,
    types: string[],
    submittingFeedback: boolean,
    hasFeedback: boolean
}>(), {
    isValid: false
});

const emit = defineEmits<{
    (e: 'update:schema', schema: UserFeedbackSchema): void;
    (e: 'submit'): void;
}>();

function close() {
}

const calculatedSchema: WritableComputedRef<UserFeedbackSchema> = computed({
    get(): UserFeedbackSchema {
        return props.schema;
    },
    set(newValue: UserFeedbackSchema): void {
        emit('update:schema', newValue);
    },
});

const type = computed<string>({
    get(): string {
        return calculatedSchema.value.type;
    },
    set(newValue: string): void {
        calculatedSchema.value.type = newValue;
    },
});

const title = computed<string|null>({
    get(): string|null {
        return calculatedSchema.value.title;
    },
    set(newValue: string|null): void {
        calculatedSchema.value.title = newValue;
    },
});

const text = computed<string|null>({
    get(): string|null {
        return calculatedSchema.value.text;
    },
    set(newValue: string|null): void {
        calculatedSchema.value.text = newValue;
    },
});

const takeScreenshot = computed<boolean>({
    get(): boolean {
        return calculatedSchema.value.takeScreenshot;
    },
    set(newValue: boolean): void {
        calculatedSchema.value.takeScreenshot = newValue;
    },
});

defineExpose({
    close
});

</script>

<style scoped>
.user-feedback-floating {
    right: 0.5rem;
    bottom: 0.5rem;
    position: fixed;
}

</style>
