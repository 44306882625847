<template>
    <bug-feedback-tailwind
        v-if="props.theme === 'tailwind'"
        ref="feedbackstrategy"
        :is-valid="isValid"
        :types="types"
        v-model:schema="schema"
        :has-feedback="hasFeedback"
        @submit="submit"
        :submitting-feedback="submittingFeedback">

    </bug-feedback-tailwind>
    <bug-feedback-bootstrap
        v-else
        ref="feedbackstrategy"
        :has-feedback="hasFeedback"
        :is-valid="isValid"
        :types="types"
        v-model:schema="schema"
        @submit="submit"
        :submitting-feedback="submittingFeedback">

    </bug-feedback-bootstrap>
</template>

<script setup lang="ts">

import BugFeedbackTailwind from './BugFeedbackTailwind.vue';
import BugFeedbackBootstrap from './BugFeedbackBootstrap.vue';
import {UserFeedbackRequest, UserFeedbackSchema} from '../../Types/UserFeedback';
import {computed, ref} from 'vue';
import axios from 'axios';
import platform from 'platform-detect';
import {ScreenTool} from './screenshot';

const props = withDefaults(defineProps<{
    theme: 'tailwind' | 'bootstrap',
    types: string[],
    hasFeedback: boolean
}>(), {
    theme: 'bootstrap'
});

const schema = ref<UserFeedbackSchema>({
    title: null,
    text: null,
    type: 'idea',
    takeScreenshot: true
});

const isValid = computed<boolean>(() => {
    return schema.value.text !== null && schema.value.text !== '';
});

const submittingFeedback = ref<boolean>(false);

const feedbackstrategy = ref<BugFeedbackTailwind | BugFeedbackBootstrap>(null);

function getPostRequestBody(): Promise<object> {
    return new Promise<object>((resolve, reject) => {
        console.log(platform);
        let requestBody: UserFeedbackRequest = {
            title: schema.value.title,
            text: schema.value.text ?? '',
            type: schema.value.type,
            client_details: platform,
        };
        if (schema.value.takeScreenshot) {
            ScreenTool.getScreenshot()
                .then(canvas => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        requestBody.screenshot = reader.result;
                        resolve(requestBody);
                    };
                    reader.readAsDataURL(canvas);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        } else {
            resolve(requestBody);
        }
    });

}

function submit() {
    submittingFeedback.value = true;
    getPostRequestBody()
        .then(body => {
            axios.post('/api/user-feedback/feedback', body)
                .then(response => alert('Feedback submitted successfully with ID #' + response.data.id))
                .catch(error => alert(error.message))
                .finally(() => {
                    submittingFeedback.value = false;
                    schema.value = {
                        title: null,
                        text: null,
                        type: 'idea',
                        takeScreenshot: true
                    };
                    feedbackstrategy.value?.close();
                });
        });


}

</script>

<style scoped>

</style>
