import html2canvas from 'html2canvas';

export class ScreenTool {
    static getInfo() {
        return {
            height: window.screen.height,
            width: window.screen.width,
            pixelRatio: window.devicePixelRatio
        };
    }

    static getScaleFromWidth(width: number): number {
        return width / screen.width;
    }

    static getScaleFromHeight(height: number): number {
        return height / screen.height;
    }

    static async getScreenshot(): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const div = document.body;
            const options = {
                background: 'white',
                height: div.scrollHeight,
                width: div.scrollWidth,
                logging: false,
                imageTimeout: 1500,
                scale: null,
                useCORS: true,
            };
            const imageType = 'image/png';
            const compression = 0.5;
            const scaleFromWidth = window.devicePixelRatio;
            const scaleFromHeight = window.devicePixelRatio;
            options.scale = Math.min(scaleFromWidth, scaleFromHeight);
            html2canvas(div as HTMLElement, options)
                .then((canvas: HTMLCanvasElement) => {
                    if (canvas.toBlob) {
                        canvas.toBlob((result: Blob) => {
                            resolve(result);
                        }, imageType, compression);
                    } else {
                        const dataUrl = canvas.toDataURL(imageType, compression);
                        resolve(ScreenTool.dataURLtoBlob(dataUrl));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    static dataURLtoBlob(dataurl) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const uarr8 = new Uint8Array(n);

        while (n--) {
            uarr8[n] = bstr.charCodeAt(n);
        }
        return new Blob([uarr8], {type: mime});
    }

}
