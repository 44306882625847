<template>
    <div> <!-- keep this single "parent" div in your template -->

        <!-- your Blade layout here -->

        <div class="card my-4 col-8 offset-2 p-0">
            <div class="card-body alert-secondary text-center">
                <option-button-selector :key="allChecksValue" :editable="false" :options="allChecksOptions"
                                        :value="allChecksValue" label="All Checks"/>
                <option-button-selector :editable="!this.return.completed_date" :options="refundTypeOptions" :value="this.return.refund_type"
                                        label="Refund Type" v-on:valueUpdate="refundTypeUpdate"/>
                <option-button-selector v-if="showCustomerAwareSelection" :editable="!this.return.completed_date" :options="customerAwareOptions"
                                        :value="this.return.customer_aware" label="Customer Aware"
                                        v-on:valueUpdate="customerAwareUpdate"/>
                <text-area-entry :is-disabled="this.return.completed_date" :value="this.return.notes" label="Notes"
                                 v-on:valueUpdate="notesUpdate"/>
            </div>
        </div>

    </div>
</template>

<script>
import OptionButtonSelector from '../../../../Components/OptionButtonSelector.vue';
import TextAreaEntry from '../../../../Components/Form/TextAreaEntry.vue';

export default {
    components: {OptionButtonSelector, TextAreaEntry},
    props: {
        serviceCenterId: String,
        return: Object
    },
    computed: {
        showCustomerAwareSelection() {
            return this.return.refund_type !== null && this.return.refund_type !== 'not_applicable';
        },
        allChecksValue() {
            return this.return.all_checks;
        }
    },
    data() {
        return {
            allChecksOptions: [{
                text: 'Pass',
                value: true,
                style: 'btn-outline-success',
                selectedStyle: 'btn-success text-white'
            }, {
                text: 'Fail',
                value: false,
                style: 'btn-outline-danger',
                selectedStyle: 'btn-danger text-white'
            }],
            refundTypeOptions: [
                {
                    value: 'not_applicable',
                    text: 'Not Applicable',
                    style: 'btn-outline-secondary',
                    selectedStyle: 'btn-secondary text-white'
                },
                {
                    value: 'full',
                    text: 'Full',
                    style: 'btn-outline-success',
                    selectedStyle: 'btn-success text-white'
                },
                {
                    value: 'partial',
                    text: 'Partial',
                    style: 'btn-outline-warning',
                    selectedStyle: 'btn-warning text-dark'
                },
                {
                    value: 'no_refund',
                    text: 'No Refund',
                    style: 'btn-outline-danger',
                    selectedStyle: 'btn-danger text-white'
                },
            ],
            customerAwareOptions: [
                {
                    text: 'Yes',
                    value: true,
                    style: 'btn-outline-success',
                    selectedStyle: 'btn-success text-white'
                },
                {
                    text: 'No',
                    value: false,
                    style: 'btn-outline-danger',
                    selectedStyle: 'btn-danger text-white'
                }
            ],
        };
    },
    methods: {
        refundTypeUpdate(newValue) {
            let tmpReturn = this.return;
            tmpReturn.refund_type = newValue;
            this.$emit('returnUpdate', tmpReturn);
        },
        customerAwareUpdate(newValue) {
            let tmpReturn = this.return;
            tmpReturn.customer_aware = newValue;
            this.$emit('returnUpdate', tmpReturn);
        },
        notesUpdate(newValue) {
            let tmpReturn = this.return;
            tmpReturn.notes = newValue;
            this.$emit('returnUpdate', tmpReturn);
        },
    },
};
</script>
