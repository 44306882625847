<template>
    <div class="mb-5">

        <check-in-details v-model="this.return" :service-center-id="this.serviceCenterId" :editable="true" :return-reason-options="returnReasonOptions" :form-errors="formErrors"></check-in-details>

        <button class="col m-1 btn btn-lg btn-primary" :disabled="this.saving" @click="clickCheckIn">CHECK IN</button>
        <button class="col m-1 btn btn-lg btn-warning" :disabled="this.saving" @click="clickCheckInStartProcessing">CHECK IN & START PROCESSING</button>
        <button class="col m-1 btn btn-lg btn-danger" :disabled="this.saving" @click="cancelCheckIn">CANCEL CHECK IN</button>
    </div>
</template>

<script>
import CheckInDetails from './ReturnComponents/CheckInDetails.vue';
import axios from 'axios';
export default 
{
    components: { CheckInDetails },
    props: {
        serviceCenterId: {
            required: true,
            type: Number
        }
    },
    data () {
        return {
            return: { check_in_user_id: null, recieved_date_localized: null }, //this default is messy but I couldn't get it to work in the component..
            formErrors: [],
            saving: false
        };
    },
    methods: {
        clickCheckIn() {
            this.saving = true;
            axios.post('/api/tools/servicecenter/' + this.serviceCenterId + '/returnslog', this.return)
                .then(() => {
                    window.location.href = '/tools/servicecenter/' + this.serviceCenterId + '/returnslog/index';
                    this.saving = false;
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.formErrors = error.response.data.errors || [];
                    }
                    else {
                        console.log(error);
                    }
                    this.saving = false;
                });
        },
        clickCheckInStartProcessing() {
            this.saving = true;
            axios.post('/api/tools/servicecenter/' + this.serviceCenterId + '/returnslog', this.return)
                .then((data) => {
                    window.location.href = '/tools/servicecenter/' + this.serviceCenterId + '/returnslog/' + data.data.id + '/edit/';
                    this.saving = false;
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.formErrors = error.response.data.errors || [];
                    }
                    else {
                        console.log(error);
                    }
                    this.saving = false;
                });
        },
        cancelCheckIn() {
            window.location.href = '/tools/servicecenter/' + this.serviceCenterId + '/returnslog/index';
        }
    }

};
</script>
