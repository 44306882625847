import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import * as Sentry from '@sentry/vue';

import Return from './Tools/ServiceCenter/ReturnsLog/Return.vue';
import ReturnCheckIn from './Tools/ServiceCenter/ReturnsLog/ReturnCheckIn.vue';
import BugFeedback from './Components/Feedback/BugFeedback.vue';

import './bootstrap';

// app.js
import { createApp } from 'vue';

Sentry.init({
    app: [],
    dsn: 'https://6c0a2f2080f34bbbbdb911efaedd3595@o842162.ingest.sentry.io/4505324510314496',
    // eslint-disable-next-line no-undef
    release: APP_VERSION,
    // eslint-disable-next-line no-undef
    enabled: process.env.NODE_ENV === 'production',
    // eslint-disable-next-line no-undef
    environment: process.env.NODE_ENV,
    integrations: [
        new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/tools\.swytchbike\.com\/api/],
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    ignoreErrors: [
        'Request aborted',
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
});

if (document.getElementById('returnslog')) {
    const returnsLogApp = createApp();
    returnsLogApp.component('return-template', Return);
    returnsLogApp.component('return-check-in-template', ReturnCheckIn);

    returnsLogApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
    Sentry.attachErrorHandler(returnsLogApp);

    returnsLogApp.mount('#returnslog');
}

if(document.getElementById('defaultVueApp')) {
    const defaultVueApp = createApp();

    defaultVueApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
    Sentry.attachErrorHandler(defaultVueApp);
    defaultVueApp.use(registerTailwindComponents);

    defaultVueApp.mount('#defaultVueApp');
}

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

// Tools
import './../../tools/wifmb/resources/js/app.js';
// import './../../tools/watson/resources/js/app';


// Services
import './../../services/circuit/resources/js/app';
import registerTailwindComponents from './registerTailwindComponents';

const feedbackApp = createApp();
feedbackApp.component('bug-feedback', BugFeedback);
feedbackApp.mount('#bug-feedback');
